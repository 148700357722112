
  import { Component, Prop } from 'vue-property-decorator'

  import Expandable from '../expandable.vue'

  import { Financing } from '@/entities/loans'

@Component({
  components: {
    Expandable,
    HeaderSection: () => import('./header.vue'),
    ContentSection: () => import('./content.vue'),
  },
})
  export default class ExpandableFinancing extends Expandable {
  @Prop({ type: Number, required: true, default: 0 }) pie!: Number;
  declare value: Financing;

  get header () {
    const { value: financing, icon } = this
    return { financing, icon }
  }

  get content () {
    const { value, icon } = this
    return { evaluations: value?.evaluations, icon }
  }

  get expandable () {
    const { value: loan } = this
    return !loan.evaluation.status.isPending
  }
  }
