export type CustomRowComponent =
  'appraisal'
  | 'stock'
  | 'person'
  | 'account'
  | 'loans'
  | 'last-appraisal-response'
  | 'status'
  | 'cav-validation'
  | 'expense-details'
  | 'address'
  | 'labor-data'
  | 'payment-order'
  | 'category-summary'
  | 'genio-qualification'
  | 'multiple-person'
  | 'appraisal-analysis'
  | 'last-appraisals'
  | 'negotiation-executive'
  | 'negotiation-supervisor'
  | 'person-resume'
  | 'appraisal-resume'
  | 'consignment-payment'
export const Components = {
  Appraisal: () => import('./appraisal.vue'),
  Stock: () => import('./StockPrices.vue'),
  Person: () => import('./ExpandablePerson.vue'),
  Account: () => import('./ExpandableAccount.vue'),
  Loans: () => import('./Loans.vue'),
  LastAppraisalResponse: () => import('./LastAppraisalResponse.vue'),
  Status: () => import('./RowStatus.vue'),
  CavValidation: () => import('./CavValidation.vue'),
  ExpenseDetail: () => import('./ExpandableExpenseDetail.vue'),
  Address: () => import('./ExpandableAddress.vue'),
  PaymentOrder: () => import('./ExpandablePaymentOrder.vue'),
  CategorySummary: () => import('./CategorySummary.vue'),
  GenioQualification: () => import('./GenioQualification.vue'),
  MultiplePerson: () => import('./ExpandableMultiplePerson.vue'),
  LaborData: () => import('./ExpandableLaborData.vue'),
  AppraisalAnalysis: () => import('./ExpandableAppraisalAnalysis.vue'),
  LastAppraisals: () => import('./ExpandableLastAppraisals.vue'),
  NegotiationExecutive: () => import('./NegotiationExecutive.vue'),
  NegotiationSupervisor: () => import('./NegotiationSupervisor.vue'),
  AppraisalResume: () => import('./AppraisalResume.vue'),
  PersonResume: () => import('./PersonResume.vue'),
}

export default Components
