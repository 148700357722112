export type ExpandableRowComponent = 'auto' | 'financing'

export const Components = {
  Auto: () => import('./auto'),
  Financing: () => import('./financing'),
}
export default Components

export { default as Expandable } from './expandable.vue'
export * from './auto'
export * from './financing'
export * from './panels'
