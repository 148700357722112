
  import { Component, Prop, Watch } from 'vue-property-decorator'

  import Expandable from '../expandable.vue'
  import ContentSection from './content.vue'
  import HeaderSection from './header.vue'

  import { Attribute } from '@/entities/vehicle'
  import { Deal } from '@/entities/crm'
  import { Auto } from '@/entities/public'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import { mapActions } from 'vuex'

@Component({
  components: {
    StockPhotos,
    Expandable,
    ContentSection,
    HeaderSection,
  },
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class ExpandableAuto extends Expandable {
  @Prop({ type: Object }) chip?: Record<string, any>;
  @Prop({ type: Boolean, default: undefined }) detailed?: boolean;
  @Prop({ type: Boolean, default: true }) findStock?: boolean;

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  km = null
  owner = null
  photos = []
  declare value: Deal | Auto;
  declare queries: {
    attributes: Promise<Array<Attribute>>
  }

  get header () {
    const { label: title, chip, auto, km, owner } = this

    return { auto, title, chip, km, owner }
  }

  get auto () {
    const { value } = this

    if (value instanceof Deal) {
      return value.auto
    } else {
      return value
    }
  }

  get content () {
    const { auto, detailed } = this
    return { auto, detailed }
  }

  async getAutoAttribute (value) {
    let km

    if (value instanceof Deal) {
      const { autoAttributes } = value as Deal

      km = autoAttributes?.find(attr => attr.slug === 'mileage')?.val
      const findOwner = autoAttributes?.find(attr => attr.slug === 'owners_number')?.val
      this.owner = findOwner ? findOwner.includes('Único') ? 'Único' : findOwner : findOwner
      km = km ? Number(km) : null
      if (km) {
        km = km.toLocaleString('de-DE')
      }
      this.km = km
      return
    }

    if (value instanceof Auto) {
      const { id } = value

      if (!id) return

      const filter = {
        _and: [
          { id_auto: { _eq: id } },
          { component: { slug: { _in: ['mileage', 'owners_number'] } } },
        ],
      }
      const attributes = await this.fetchData({
        query: { name: 'find', model: 'AttributeView', order: { id_attribute: 'desc' } },
        filter,
        force: true,
      })

      this.km = attributes?.find(attr => attr.slug === 'mileage')?.val
      const owner = attributes?.find(attr => attr.slug === 'owners_number')?.val

      this.owner = owner ? owner.includes('Único') ? 'Único' : owner : owner
    }
  }

  @Watch('value', { immediate: true, deep: true })
  async onValueChange () {
    await this.getAutoAttribute(this.value)
  }
  }
