import Expandable from './expandable'
import Custom from './custom'

export const Components = {
  Row: () => import('./simple.vue'),
  Simple: () => import('./simple.vue'),
  BtnLink: () => import('./link.vue'),
  Chip: () => import('./chip.vue'),
  ...Expandable,
  ...Custom,
}

export default Components
